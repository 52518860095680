import { Day } from "./Stats";
import { GameState } from "./Game";

interface StatusViewProps {
  day: Day | null;
  status: string | null;
}

export function StatusView({ status, day }: StatusViewProps) {
  const gameState = day ? day.gameState : null;
  const guesses = day ? (gameState === GameState.Lost ? "X" : day.guesses.length) : "-";

  const openInNewWindow = (url: string) => (e: React.MouseEvent) => {
    e.preventDefault();
    window.open(url, '_blank');
  };

  const preventNavigation = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const rootStyle = {
    margin: 0,
    padding: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'transparent',
    boxSizing: 'border-box' as const,
  };

  const containerStyle = {
    display: 'flex',
    flexDirection: 'column' as const,
    padding: '8px 12px',
    fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif',
    backgroundColor: '#1a1a1a',
    color: '#ffffff',
    borderRadius: '8px',
    gap: '8px',
    boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
    width: 'fit-content',
    maxWidth: '100%',
    boxSizing: 'border-box' as const,
  };

  const topRowStyle = {
    display: 'flex',
    alignItems: 'center',
    gap: '8px'
  };

  const bottomRowStyle = {
    display: 'flex',
    alignItems: 'center',
    gap: '12px',
    paddingTop: '4px'
  };

  const titleStyle = {
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
    fontSize: '16px',
    fontWeight: 500,
    color: '#ffffff',
    textDecoration: 'none'
  };

  const authorStyle = {
    fontSize: '14px',
    color: '#999',
    textDecoration: 'none'
  };

  const statusStyle = {
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
    fontSize: '16px',
    fontWeight: 500,
    padding: '4px 8px',
    borderRadius: '6px',
    backgroundColor: gameState === GameState.Won ? '#1a472a' : 
                    gameState === GameState.Lost ? '#5c1a1a' :
                    gameState === GameState.Playing ? '#2a3b4d' : 'transparent'
  };

  const rulesStyle = {
    fontSize: '14px',
    color: '#999'
  };

  return (
    <div style={rootStyle}>
      <div style={containerStyle}>
        <div style={topRowStyle}>
          <img 
            width="24" 
            height="24" 
            src="favicon.png" 
            alt="Xordle"
            style={{ borderRadius: '4px' }}
          />
          <a href="https://xordle.org" onClick={openInNewWindow('https://xordle.org')} style={titleStyle}>Xordle</a>
          <span style={{ color: '#666' }}>by</span>
          <a href="https://twitter.com/kellydornhaus" onClick={openInNewWindow('https://twitter.com/kellydornhaus')} style={authorStyle}>keldor</a>
        </div>

        {(
          <div style={bottomRowStyle}>
            {gameState !== null && (
              <>
                {gameState === GameState.Won && (
                  <div style={statusStyle}>✅ {guesses}/8</div>
                )}
                {gameState === GameState.Lost && (
                  <div style={statusStyle}>💀 X/8</div>
                )}
                {gameState === GameState.Playing && (
                  <div style={statusStyle}>🎲 {guesses}/8</div>
                )}
              </>
            )}
            
            {status === "full" && (
              <div style={rulesStyle}>
                Two secret words, one board, no overlap between the words.
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
} 